import L from 'leaflet';
import '~/lib/leaflet.layer.yandex';
import '~/lib/leaflet.layer.google';
import {ImageTransformLayer} from '~/lib/leaflet.layer.ImageTransform';

import '~/lib/leaflet.layer.soviet-topomaps-grid';
import {LatviaTopoGrid} from '~/lib/leaflet.layer.latvia-topo-grid';
import {VesturesStastiLayer} from '~/lib/leaflet.layer.vesture';
import {RobezpunktiLayer} from '~/lib/leaflet.layer.robezpunkti';
import {DodiesLvLayer} from '~/lib/leaflet.layer.dodieslv';
import {mbTilesLayer} from '~/lib/leaflet.layer.protomaps/protomaps.min';

    function anrijs_tiles(url) {
        return 'https://tiles.anrijs.lv/' + url + '/{z}/{x}/{y}.png';
    }

    function anrijs_quickLayer(title, url, code, maxZoom, options = {}) {
        return {
            title: title,
            isDefault: options.default || false,
            layer: L.tileLayer(url,
                {
                    code: code,
                    isOverlay: options.isOverlay || true,
                    tms: options.tms || false,
                    print: options.print || true,
                    jnx: options.print || true,
                    scaleDependent: options.scaleDependent || false,
                    shortName: options.shortName || code,
                    isOverlayTransparent: options.isOverlayTransparent || false,
                    maxNativeZoom: maxZoom,
                    minZoom: options.minZoom || 0,
                    noOpacity: options.noOpacity || false,
                    hotkey: options.hotkey || undefined,
                    attribution: options.attribution || undefined,
                    modkey: options.modkey || undefined,
                    opacity: options.opacity || undefined,
              })
        };
    }

    const layersDefs = [
                {
                    title: 'OpenStreetMap',
                    group: 'Pamatnes',
                    description: 'OSM default style',
                    isDefault: true,
                    layer: L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png',
                        {
                            code: 'O',
                            isOverlay: false,
                            scaleDependent: true,
                            print: true,
                            jnx: true,
                            shortName: 'osm',
                            maxNativeZoom: 19,
                            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">' +
                                'OpenStreetMap</a> contributors'
                        }
                    )
                },
                {
                    title: 'ESRI Sat',
                    group: 'Pamatnes',
                    isDefault: true,
                    layer: L.tileLayer(
                        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                        {
                            code: 'E',
                            isOverlay: false,
                            scaleDependent: false,
                            maxNativeZoom: 18,
                            maxZoom: 21,
                            print: true,
                            jnx: true,
                            shortName: 'esri',
                            attribution:
                            '<a href="https://www.arcgis.com/home/item.html?id=10df2279f9684e4a9f6a7f08febac2a9">' +
                            'ESRI World Imagery for ArcGIS</a>',
                        }
                    )
                },
                {
                    title: 'Test Layer 1',
                    isDefault: false,
                    layer: new ImageTransformLayer(
                        'https://o.anrijs.lv/getmap.php?id=234',
                        [
                            [56.99598, 24.11773], [56.99596, 24.19146], [56.96754, 24.1915], [56.96752, 24.11781]
                        ],
                        {
                            isOverlay: true,
                            code: 'Y',
                            opacity: 1,
                            disableSetClip: true
                        }
                    )
                },
                anrijs_quickLayer(
                    'Magnēts 2019', anrijs_tiles('o2019/magnets'),
                    'Om', 18, {shortName: 'magnets2019'}
                ),
                anrijs_quickLayer(
                    'Kāpa 2019', anrijs_tiles('o2019/kapa'),
                    'Ok', 18, {shortName: 'kapa2019'}
                ),
                anrijs_quickLayer(
                    '17.gs. Zviedru muižas', anrijs_tiles('17gs_muizas'),
                    'M', 16, {shortName: 'muzas'}
                ),
                anrijs_quickLayer(
                    '18-19. gs PGM', anrijs_tiles('18gs_pgm'),
                    'T', 14, {shortName: 'pgm', modkey: {shift: true}}
                ),
                anrijs_quickLayer(
                    '19.gs. Popes muiža', anrijs_tiles('popes_muiza'),
                    'P', 16, {shortName: 'popes_muiza'}
                ),
                anrijs_quickLayer(
                    '17.gs. Rīgas patrimoniālā apgabala plāns', anrijs_tiles('17gs_rigas_apgabals'),
                    'M', 13, {shortName: '17gs_riga', modkey: {shift: true}}
                ),
                anrijs_quickLayer(
                    '1752-1835 Atlas von Liefland', anrijs_tiles('1754_atlas_von_liefland'),
                    'L', 13, {shortName: '1754_atlas_von_liefland'}
                ),
                anrijs_quickLayer(
                    '1820 Geograficheskii atlas Rossiiskoi imperii',
                    anrijs_tiles('1820_geograficheskoi_atlas_rossiiskoi_inperii'),
                    'L', 13, {shortName: 'rosimp_'}
                ),
                anrijs_quickLayer(
                    '1839 Specialkarte von Livland 1:186k',
                    anrijs_tiles('1839_specialarte_von_livland_186k'),
                    'S', 13, {shortName: 'svl1839_'}
                ),
                anrijs_quickLayer(
                    '1883 Karte der stadt Riga 1:4200',
                    anrijs_tiles('1883_Karte_der_stadt_Riga'),
                    'R', 18, {shortName: 'riga1883_'}
                ),
                anrijs_quickLayer(
                    '1885 Stadplan von Riga 1:12600',
                    anrijs_tiles('1885_stadplan_von_riga'),
                    'I', 17, {shortName: 'riga1885_'}
                ),
                anrijs_quickLayer(
                    '1904 Karte des wistlichen Ruslands 1:100k',
                    anrijs_tiles('1904_kdwr_100k'),
                    'W', 14, {shortName: 'Kdwr_'}
                ),
                anrijs_quickLayer(
                    '1924 WIG 1:100k',
                    anrijs_tiles('1924_wig_100k'),
                    'G', 14, {shortName: 'wig_'}
                ),
                anrijs_quickLayer(
                    '1914-1919 West. Osteuropa 1:25k',
                    anrijs_tiles('westl25k'),
                    'Q', 14, {shortName: 'westl25k'}
                ),
                anrijs_quickLayer(
                    '1867-1918 Военно-топографическая карта европейской России 1:126k',
                    anrijs_tiles('RUS_126k'),
                    'P', 12, {shortName: 'triversta'}
                ),
                anrijs_quickLayer(
                    '1915 Военно-топографическая карта европейской России 1:126k',
                    anrijs_tiles('1915_ru_126k'),
                    'bo', 13, {shortName: 'triverstaru'}
                ),
                anrijs_quickLayer(
                    '1914-1919 Niemieckie kopie rosyjskiej "trójwiorstówki" 1:126k',
                    anrijs_tiles('1915_126k'),
                    'A', 14, {shortName: 'triverstb'}
                ),
                anrijs_quickLayer(
                    '1950 P. Mantnieka 1:450k',
                    anrijs_tiles('1950_P_Mantnieka_450k'),
                    'S', 11, {shortName: 'pm1950'}
                ),
                anrijs_quickLayer(
                    '1933 P. Mantnieka 1:250k',
                    anrijs_tiles('1933_P_Mantnieka_250k'),
                    'D', 12, {shortName: 'pm1933'}
                ),
                anrijs_quickLayer(
                    '1936 Rīgas pilsētas plāns 1:24k',
                    anrijs_tiles('1936_riga_ernst_plates_24k'),
                    'F', 15, {shortName: 'rep0'}
                ),
                anrijs_quickLayer(
                    '1930 Two-verst | West Borderland Territory [RKKA] 1:86k',
                    anrijs_tiles('divverstu_86k_rkka'),
                    'H', 14, {shortName: 'divversta'}
                ),
                anrijs_quickLayer(
                    '1905 RKKA 1:100k',
                    anrijs_tiles('1905_rkka_100k'),
                    'H', 13, {shortName: 'rkka100'}
                ),
                anrijs_quickLayer(
                    '1910 Two-verst | West Borderland Territory [Orig] 1:86k',
                    anrijs_tiles('divverstu_86k_orig'),
                    'J', 14, {shortName: 'divverstb'}
                ),
                anrijs_quickLayer(
                    '1916 Feldmäßig hergestellte Fliegerkarte 1:200k',
                    anrijs_tiles('1916_fliegerkarte_200k'),
                    'fl', 12, {shortName: 'flg200k'}
                ),
                anrijs_quickLayer(
                    '1915 Ubersichtskarte von Mitteleuropa 1:300k',
                    anrijs_tiles('ubersichtskarte_300k'),
                    'L', 12, {shortName: 'ubrk300k'}
                ),
                anrijs_quickLayer(
                    '1920-1940 Latvijas Armijas 1:200k',
                    anrijs_tiles('LVARM_40_200k'),
                    'l1', 13, {shortName: 'lva200k'}
                ),
                anrijs_quickLayer(
                    '1920-1940 Latvijas Armijas 1:75k',
                    anrijs_tiles('LVARM_40_75k'),
                    'l2', 13, {shortName: 'lva75k'}
                ),
                anrijs_quickLayer(
                    '1920-1940 Latvijas Armijas 1:50k',
                    anrijs_tiles('LVARM_40_50k'),
                    'l3', 13, {shortName: 'lva50k'}
                ),
                anrijs_quickLayer(
                    '1920-1940 Latvijas Armijas 1:25k',
                    anrijs_tiles('LVARM_40_25k_v2'),
                    'l4', 15, {shortName: 'lva25k'}
                ),
                anrijs_quickLayer(
                    '1940 Latvijas Ceļu Karte 1:200k',
                    anrijs_tiles('autoceli_1940_200k'),
                    'lvc200', 12, {shortName: 'lvc200k'}
                ),
                anrijs_quickLayer(
                    '1934 Latvijas Ceļu Karte 1:300k',
                    anrijs_tiles('autoceli_1934_300k'),
                    'lvc300', 12, {shortName: 'lvc300k'}
                ),
                anrijs_quickLayer(
                    '1917 Karte von Kurland 1:50k',
                    anrijs_tiles('1917_karte_von_kurland_50k'),
                    'kvl50', 14, {shortName: 'kvl50'}
                ),
                anrijs_quickLayer(
                    '1940 RKKA 1:50k',
                    anrijs_tiles('1940_rkka_50k'),
                    'rkka50', 14, {shortName: 'rkka50k'}
                ),
                anrijs_quickLayer(
                    '1940 RKKA 1:200k',
                    anrijs_tiles('1940_rkka_200k'),
                    'rkka200', 13, {shortName: 'rkka200k'}
                ),
                anrijs_quickLayer(
                    '1940 RKKA 1:500k',
                    anrijs_tiles('1940_rkka_500k'),
                    'rkka500', 11, {shortName: 'rkka500k'}
                ),
                anrijs_quickLayer(
                    '1980 Геренальный штаб (C) 1:10k',
                    anrijs_tiles('genshtab_10k_c'),
                    '5a', 16, {shortName: 'gensh10kc'}
                ),
                anrijs_quickLayer(
                    '1980 Геренальный штаб (O) 1:10k',
                    anrijs_tiles('genshtab_10k_o'),
                    '6a', 16, {shortName: 'gensh10ko'}
                ),
                anrijs_quickLayer(
                    '1980 Геренальный штаб (Pilsētas) 1:10k',
                    anrijs_tiles('genshtab_10k_pilsetas'),
                    '7a', 16, {shortName: 'gensh10kp'}
                ),
                anrijs_quickLayer(
                    '1947 Геренальный штаб (O Grid) 1:25k',
                    anrijs_tiles('genshtab_1947_25k'),
                    '8a', 15, {shortName: 'gensh25k47_'}
                ),
                anrijs_quickLayer(
                    '1963 Геренальный штаб (C Grid) 1:25k',
                    anrijs_tiles('PSRS_42g_25k'),
                    '9a', 15, {shortName: 'gensh25k63_'}
                ),
                anrijs_quickLayer(
                    '1986 Геренальный штаб (O Grid) 1:25k',
                    anrijs_tiles('PSRS_63g_25k'),
                    '0a', 15, {shortName: 'gensh25k86_'}
                ),
                anrijs_quickLayer(
                    '1980s Геренальный штаб 1:50k',
                    anrijs_tiles('PSRS_50k'),
                    'g50', 14, {shortName: 'gensh50k'}
                ),
                anrijs_quickLayer(
                    '1980s Геренальный штаб 1:100k',
                    anrijs_tiles('PSRS_100k'),
                    'g100', 13, {shortName: 'gensh100k'}
                ),
                anrijs_quickLayer(
                    '1980s Геренальный штаб 1:200k',
                    anrijs_tiles('PSRS_200k'),
                    'g200', 12, {shortName: 'gensh200k'}
                ),
                anrijs_quickLayer(
                    '1980s Геренальный штаб 1:500k',
                    anrijs_tiles('PSRS_500k'),
                    'g500', 11, {shortName: 'gensh500k'}
                ),
                anrijs_quickLayer(
                    '1980s Геренальный штаб 1:1M',
                    anrijs_tiles('PSRS_1M'),
                    'g1000', 10, {shortName: 'gensh1m'}
                ),
                anrijs_quickLayer(
                    '1942 Deutsche Heereskarte 1:100k',
                    anrijs_tiles('1942_heereskarte_100k'),
                    'dh', 13, {shortName: 'dh100'}
                ),
                anrijs_quickLayer(
                    '1943 Deutsche Heereskarte 1:300k',
                    anrijs_tiles('1943_heereskarte_300k'),
                    'dh3', 12, {shortName: 'dh300', preview: anrijs_tiles('1943_heereskarte_300k/preview.png')}
                ),
                anrijs_quickLayer(
                    '1937 Rīga ',
                    anrijs_tiles('riga_1937'),
                    'r37', 17, {shortName: 'riga1937_'}
                ),
                anrijs_quickLayer(
                    '1940 Estland-Lettland 1:100k',
                    anrijs_tiles('1940_estland_lettland_100k'),
                    '7', 13, {shortName: 'estlet'}
                ),
                anrijs_quickLayer(
                    '1941 Strassenkarte der Baltischen Länder 1:300k',
                    anrijs_tiles('1941_Strassenkarte_der_Baltischen_300k'),
                    'el', 12, {shortName: 'estalet'}
                ),
                anrijs_quickLayer(
                    '1954 US Army 250k',
                    anrijs_tiles('US_ARMY_1954_250k'),
                    'us', 12, {shortName: 'us1950'}
                ),
                anrijs_quickLayer(
                    '1990s Rīga 1:10k',
                    anrijs_tiles('riga_1990'),
                    'r93', 17, {shortName: 'riga1990_'}
                ),
                anrijs_quickLayer(
                    '1926 Cēsis ',
                    anrijs_tiles('cesis_1926'),
                    'c26', 17, {shortName: 'cesis1926_'}
                ),
                anrijs_quickLayer(
                    '1967-1972 CORONA Satelite ',
                    anrijs_tiles('corona'),
                    'V', 15, {shortName: 'corona'}
                ),
                anrijs_quickLayer(
                    '1940s NARA Aerofoto ',
                    anrijs_tiles('aerofoto_1940'),
                    'N', 17, {shortName: 'nara'}
                ),
                anrijs_quickLayer(
                    '1908 Viena versta 1:42k',
                    anrijs_tiles('1908_versta_42k'),
                    '1v', 15, {shortName: 'vienverst'}
                ),
                anrijs_quickLayer(
                    '1930 Mērniecības planšetes 1:5k',
                    anrijs_tiles('5k_plansetes'),
                    'pl', 17, {shortName: 'merniks'}
                ),
                anrijs_quickLayer(
                    '1938 Telefonu vadu tīkla shēma',
                    anrijs_tiles('1938_telefonu_vadu_shema'),
                    'te', 12, {shortName: 'tel'}
                ),
                anrijs_quickLayer(
                    '1990 VVM Shēmas',
                    anrijs_tiles('vvm_shemas_1990'),
                    'vm', 14, {shortName: 'vvm'}
                ),
                anrijs_quickLayer(
                    '1798 Valmiera',
                    anrijs_tiles('1798_Valmiera'),
                    'val', 18, {shortName: 'val'}
                ),
                anrijs_quickLayer(
                    '1905 Specialnaja karta evropejskoj Rossii 1:420k',
                    anrijs_tiles('1905_specialnaja_karta_evropeiskoj_rosii_420k'),
                    'spri', 12, {shortName: 'spri'}
                ),
                anrijs_quickLayer(
                    '1999 VZD 1:50k',
                    anrijs_tiles('vzd_1999_50k'),
                    'vzd', 14, {shortName: 'vzd'}
                ),
                anrijs_quickLayer(
                    'DJI Ortofoto',
                    anrijs_tiles('https://geotiff.anrijs.lv/layers/5c57cdf59e2fa/{z}/{x}/{y}.png'),
                    'dji', 21, {shortName: 'dji'}
                ),
                anrijs_quickLayer(
                    '1921 Latvijas karte',
                    anrijs_tiles('1921_latvijas_karte'),
                    '2', 12, {shortName: 'lvc200k'}
                ),
                anrijs_quickLayer(
                    '1928 Latvijas satiksmes ceļu Karte 1:400k',
                    anrijs_tiles('1928_latvijas_satiksmes_celu_karte_400k'),
                    '3', 12, {shortName: 'lvc200k'}
                ),
                anrijs_quickLayer(
                    '1931 Latvijas ceļu un pagastu robežu karte 1:300k',
                    anrijs_tiles('1931_latvijas_celu_un_pagastu_robezu_karte_300k'),
                    '4', 12, {shortName: 'lvc200k'}
                ),
                anrijs_quickLayer(
                    '1935 Latvijas autoceļi 1:500k',
                    anrijs_tiles('1935_latvijas_autoceli_500k'),
                    '6', 12, {shortName: 'lvc200k'}
                ),
                anrijs_quickLayer(
                    '2013 Latvijas Republikas pārskata karte 1:250k',
                    anrijs_tiles('2013_LR_parskata_karte_250k'),
                    'lr250k', 12, {shortName: 'lr250k'}
                ),
                anrijs_quickLayer(
                    '2020 Latvijas Republikas pārskata karte 1:250k',
                    anrijs_tiles('2020_LR_parskata_karte_250k'),
                    'lr250k_v3', 12, {shortName: 'lr250k_v3'}
                ),
                anrijs_quickLayer(
                    '1933 Jaunlatgales apriņķa ceļu karte 1:50k',
                    anrijs_tiles('1933_jaunlatgales_celu_karte_50k'),
                    '5', 14, {shortName: 'jak'}
                ),
                anrijs_quickLayer(
                    '1917 Vācu armija 1:10k',
                    anrijs_tiles('1917_vacu_armija_10k'),
                    '1l', 16, {shortName: 'va'}
                ),
                anrijs_quickLayer(
                    '1917 Vācu armija 1:25k',
                    anrijs_tiles('1917_vacu_armija_25k'),
                    '1', 14, {shortName: 'va'}
                ),
                anrijs_quickLayer(
                    '1915 Operationskarte 1:800k',
                    anrijs_tiles('1915_operationskarte_800k'),
                    'z', 11, {shortName: 'va'}
                ),
                anrijs_quickLayer(
                    '1918-1919 Brīvības cīņas',
                    anrijs_tiles('1918_Brivibas_Cinas/1918-1919'),
                    'b1', 10, {shortName: 'bc1'}
                ),
                anrijs_quickLayer(
                    '1919-1920 Brīvības cīņas',
                    anrijs_tiles('1918_Brivibas_Cinas/1919-1920'),
                    'b2', 10, {shortName: 'bc2'}
                ),
                anrijs_quickLayer(
                    '1884 Special-Karte von Mittel-Europa 1:200k',
                    anrijs_tiles('1884_specialkarte_von_mitteleuropa_200k'),
                    'sk', 12, {shortName: 'sk'}
                ),
                anrijs_quickLayer(
                    '1917 Zusammendruck 1:100k',
                    anrijs_tiles('1917_zusammendruck_100k'),
                    'zu', 14, {shortName: 'zu'}
                ),
                anrijs_quickLayer(
                    '1918 Ubersichtskarte der 8. armee 1:100k',
                    anrijs_tiles('1918_ubersichtskarte_der_8_armee_100k'),
                    'ub', 14, {shortName: 'ub'}
                ),
                anrijs_quickLayer(
                    '1940 Grosßblatt 1:100k',
                    anrijs_tiles('1940_grosblatt_100k'),
                    'gr', 14, {shortName: 'gr'}
                ),
                anrijs_quickLayer(
                    '1m augstumlīknes',
                    anrijs_tiles('1metra'),
                    '1m', 17,
                    {
                        shortName: '1m',
                        attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                            'Digitālā zemes virsmas modeļa pamatdati &copy; ' +
                            'Latvijas Ģeotelpiskās informācijas aģentūra, 2016'
                    }
                ),
                {
                    title: "1914-1919 West. Osteuropa 1:25k PMTILES",
                    isDefault: true,
                    layer: mbTilesLayer("https://tiles.anrijs.lv/westl25k.mbtiles",
                        {
                            code: "westl25k_pm",
                            isOverlay: true,
                            tms: false,
                            print: true,
                            jnx: true,
                            scaleDependent: false,
                            shortName: "westl25k_pm",
                            isOverlayTransparent: false,
                            maxNativeZoom: 14,
                            minZoom: 0,
                            noOpacity: false,
                            hotkey: "Q",
                            attribution: undefined,
                            modkey: {shift: true},
                            opacity: undefined,
                      })
                },
                {
                    title: 'Ziemeļvidzemes noklusētie stāsti',
                    group: 'Datu slāņi',
                    isDefault: false,
                    layer: new VesturesStastiLayer(
                        'https://tiles.anrijs.lv/vestures_stasti/',
                        {
                            code: 'Z',
                            isOverlay: true,
                            noOpacity: true,
                            tms: false,
                            print: true,
                            jnx: true,
                            scaleDependent: false,
                            shortName: 'geo_',
                            isOverlayTransparent: false,
                            maxNativeZoom: 18,
                            minZoom: 0,
                            notes: 'Slānis\'Ziemeļvidzemes noklusētie stāsti\' veidots, lai publiski saglabātu ' +
                                  'pieeju Igaunijas - Latvijas programmas projekta \'Neapzinātās kultūras mantojuma ' +
                                  'vērtības kopējā dabas un kultūras telpā\' ietvaros apsekoto objektu datubāzi. ' +
                                  'Vairāk par projektu <a href=\'https://www.daba.gov.lv/public/lat/projekti/' +
                                  'istenotie_projekti/interreg_neapzinatais_kulturas_mantojums/#Aktualitates\'>' +
                                  'DAP mājaslapā</a>, kā arī <a href=\'https://home.dodies.lv/tiles/vmd-bildes/' +
                                  'VMD_Ziemelvidzemes_stasti.pdf\'>grāmata PDF formātā</a>'
                        }
                    )
                },
                anrijs_quickLayer(
                    '1936 Robeža LV-PL 1:4000', 'Datu slāņi',
                    anrijs_tiles('1936_lv_pl_robeza'),
                    'pl', 18, {shortName: 'pl'}
                ),
                anrijs_quickLayer(
                    '1867 Gouvernements-Stadt Riga. Wieckmann',
                    anrijs_tiles('1867_Gouvernements-Stadt_Riga_Wieckmann'),
                    'Wieck', 17, {shortName: 'Wieck'}
                ),
                {
                    title: 'dodies.lv Takas',
                    group: 'Datu slāņi',
                    isDefault: true,
                    layer: new DodiesLvLayer("https://tiles.anrijs.lv/",
                        {
                            geojsonUrl: 'dodies/lvtaka.geojson',
                            aprakstsBaseUrl: 'https://dodies.lv/',
                            routesUrl: 'dodies/routes/',
                            code: 'lvtaka',
                            isOverlay: true,
                            noOpacity: true,
                            scaleDependent: false,
                            shortName: 'lvtaka',
                      })
                },
                {
                    title: 'dodies.lv Torņi',
                    group: 'Datu slāņi',
                    isDefault: true,
                    layer: new DodiesLvLayer("https://tiles.anrijs.lv/",
                        {
                            geojsonUrl: 'dodies/lvtornis.geojson',
                            aprakstsBaseUrl: 'https://dodies.lv/',
                            code: 'lvtornis',
                            isOverlay: true,
                            noOpacity: true,
                            scaleDependent: false,
                            shortName: 'lvtornis',
                      })
                },
                {
                    title: 'dodies.lv Pikniki',
                    group: 'Datu slāņi',
                    isDefault: true,
                    layer: new DodiesLvLayer("https://tiles.anrijs.lv/",
                        {
                            geojsonUrl: 'dodies/lvpikniks.geojson',
                            aprakstsBaseUrl: 'https://dodies.lv/',
                            code: 'lvpikniks',
                            isOverlay: true,
                            noOpacity: true,
                            scaleDependent: false,
                            shortName: 'lvpikniks',
                      })
                },
                {
                    title: '1928 Robežpunkti LV-EE 1:25k',
                    group: 'Datu slāņi',
                    isDefault: false,
                    layer: new RobezpunktiLayer(
                        'https://tiles.anrijs.lv/1928_lv_ee_robezpunkti/',
                        {
                            code: 'robeza',
                            isOverlay: true,
                            noOpacity: true,
                            tms: false,
                            print: true,
                            jnx: true,
                            scaleDependent: false,
                            shortName: 'robeza_',
                            isOverlayTransparent: false,
                            maxNativeZoom: 15,
                            minZoom: 0,
                            notes: ''
                        }
                    )
                },
                {
                    title: 'LVM DVM',
                    group: 'LVM / LGIA',
                    isDefault: true,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {
                            scaleDependent: true,
                            print: true,
                            layers: 'public:ZemeLKS',
                            code: 'dtm',
                            isOverlay: true,
                            tms: false,
                            jnx: true,
                            shortName: 'va',
                            isOverlayTransparent: false,
                            maxNativeZoom: 18,
                            minZoom: 0,
                            attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                                'Digitālā zemes virsmas modeļa pamatdati &copy; ' +
                                'Latvijas Ģeotelpiskās informācijas aģentūra, 2016',
                      })
                },
                {
                    title: 'LVM Ortofoto',
                    group: 'LVM / LGIA',
                    isDefault: true,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {
                            scaleDependent: true,
                            print: true,
                            layers: 'public:Orto_LKS',
                            code: 'orto',
                            isOverlay: true,
                            tms: false,
                            jnx: true,
                            shortName: 'orto',
                            hotkey: '0',
                            modkey: {
                                shift: true
                            },
                            isOverlayTransparent: false,
                            maxNativeZoom: 18,
                            maxZoom: 20,
                            minZoom: 0,
                            attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                                'Digitālā ',
                      })
                },
                {
                    title: 'LVM Ortofoto 1. Cikls (1994-1999)',
                    group: 'LVM / LGIA',
                    isDefault: true,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {
                            scaleDependent: true,
                            print: true,
                            layers: 'public:Orto_1cikls',
                            code: 'orto1',
                            isOverlay: true,
                            tms: false,
                            jnx: true,
                            shortName: 'orto1',
                            hotkey: '1',
                            modkey: {
                                shift: true
                            },
                            isOverlayTransparent: false,
                            maxNativeZoom: 18,
                            minZoom: 0,
                            attribution: '',
                      })
                },
                {
                    title: 'LVM Ortofoto 2. Cikls (2003-2005)',
                    group: 'LVM / LGIA',
                    isDefault: false,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {
                            scaleDependent: true,
                            print: true,
                            layers: 'public:Orto_2cikls',
                            code: 'dtm',
                            isOverlay: true,
                            tms: false,
                            jnx: true,
                            shortName: 'orto2',
                            hotkey: '2',
                            modkey: {
                                shift: true
                            },
                            isOverlayTransparent: false,
                            maxNativeZoom: 18,
                            minZoom: 0,
                            attribution: '',
                      })
                },
                {
                    title: 'LVM Ortofoto 3. Cikls (2007-2008)',
                    group: 'LVM / LGIA',
                    isDefault: false,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {
                            scaleDependent: true,
                            print: true,
                            layers: 'public:Orto_3cikls',
                            code: 'dtm',
                            isOverlay: true,
                            tms: false,
                            jnx: true,
                            shortName: 'orto3',
                            hotkey: '3',
                            modkey: {
                                shift: true
                            },
                            isOverlayTransparent: false,
                            maxNativeZoom: 18,
                            minZoom: 0,
                            attribution: '',
                      })
                },
                {
                    title: 'LVM Ortofoto 4. Cikls (2010-2011)',
                    group: 'LVM / LGIA',
                    isDefault: false,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {
                            scaleDependent: true,
                            print: true,
                            layers: 'public:Orto_4cikls',
                            code: 'orto4',
                            isOverlay: true,
                            tms: false,
                            jnx: true,
                            shortName: 'orto4',
                            hotkey: '4',
                            modkey: {
                                shift: true
                            },
                            isOverlayTransparent: false,
                            maxNativeZoom: 18,
                            minZoom: 0,
                            attribution: '',
                      })
                },
                {
                    title: 'LVM Ortofoto 5. Cikls (2013-2015)',
                    group: 'LVM / LGIA',
                    isDefault: false,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {
                            scaleDependent: true,
                            print: true,
                            layers: 'public:Orto_5cikls',
                            code: 'orto5',
                            isOverlay: true,
                            tms: false,
                            jnx: true,
                            shortName: 'orto5',
                            hotkey: '5',
                            modkey: {
                                shift: true
                            },
                            isOverlayTransparent: false,
                            maxNativeZoom: 18,
                            minZoom: 0,
                            attribution: '',
                      })
                },
                {
                    title: 'LVM Ortofoto 6. Cikls (2016-2018)',
                    group: 'LVM / LGIA',
                    isDefault: false,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {
                            scaleDependent: true,
                            print: true,
                            layers: 'public:Orto_6cikls',
                            code: 'orto6',
                            isOverlay: true,
                            tms: false,
                            jnx: true,
                            shortName: 'orto6',
                            hotkey: '6',
                            modkey: {
                                shift: true
                            },
                            isOverlayTransparent: false,
                            maxNativeZoom: 18,
                            minZoom: 0,
                            attribution: '',
                      })
                },
                {
                    title: 'Kadastrs OpenData',
                    group: 'Datu slāņi',
                    isDefault: false,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {
                            scaleDependent: true,
                            print: true,
                            layers: 'publicwfs:Kadastra_karte',
                            format: 'image/png',
                            transparent: true,
                            code: 'kad',
                            isOverlay: true,
                            jnx: true,
                            maxZoom: 21,
                            hotkey: 'K',
                            modkey: {
                                shift: true
                            },
                            attribution: '<a href="https://www.lvmgeo.lv/dati">LVM</a>, ' +
                                'Kadastra informācijas sistēmas atvērtie telpiskie dati. ' +
                                'WMS servisu nodrošina LVMGEO. CC BY 4.0',
                      })
                },
                {
                    title: 'LMT 5G',
                    group: 'Mobilo tīklu pārklājums',
                    isDefault: false,
                    layer: L.tileLayer.wms('https://karte.lmt.lv/geoserver/gwc/service/wms?',
                        {
                            scaleDependent: false,
                            print: true,
                            layers: 'lmt:internet_5g',
                            isOverlay: true,
                            tms: false,
                            jnx: true,
                            code: 'lmt5g',
                            shortName: 'lmt',
                            format: 'image/png',
                            isOverlayTransparent: true,
                            opacity: 0.7,
                            maxNativeZoom: 18,
                            minZoom: 0,
                            attribution: '<a href="https://karte.lmt.lv/">LMT Pārklājuma karte</a>',
                      })
                },
                {
                    title: 'TELE2 5G',
                    group: 'Mobilo tīklu pārklājums',
                    isDefault: false,
                    layer: L.tileLayer(
                        'https://wms2.kartes.lv/TEL2/wgs/TELE2G5/{z}/{x}/{y}.png',
                        {
                            code: 't2',
                            isOverlay: true,
                            scaleDependent: false,
                            maxNativeZoom: 18,
                            opacity: 0.7,
                            print: true,
                            jnx: true,
                            shortName: 'tele2',
                            attribution:
                            'https://www.tele2.lv/karte/',
                        }
                    )
                },
                {
                    title: 'TKS-93 karšu nomenklatūras lapas',
                    group: 'Datu slāņi',
                    isDefault: true,
                    layer: new LatviaTopoGrid({
                        code: 'tks',
                        isOverlay: true,
                        print: false,
                        jnx: false
                    })
                },
                anrijs_quickLayer(
                    'LMT 5G 2023-07-07', 'Mobilo tīklu pārklājums', anrijs_tiles('parklajums/lmt/2023-07-07'),
                    'lmt_1', 13, {shortName: 'lmt_1', opacity: 0.7}
                ),
                anrijs_quickLayer(
                    'TELE2 5G 2023-03-09', 'Mobilo tīklu pārklājums', anrijs_tiles('parklajums/tele2/2023-03-09'),
                    'tele2_2', 12, {shortName: 'tele2_2', opacity: 0.7}
                ),
                anrijs_quickLayer(
                    'Bite 5G', 'Mobilo tīklu pārklājums', anrijs_tiles('parklajums/bite/latest'),
                    'bite', 13, {shortName: 'bite', opacity: 0.7}
                ),
    ];

    const groupsDefs = [
        {
            title: 'Pamatnes',
            collapse: true,
            layers: [
                'OpenStreetMap',
                'ESRI Sat',
            ],
        },
        {
            title: 'Mobilo tīklu pārklājums',
            collapse: true,
            layers: [
                'LMT 5G',
                'LMT 5G 2023-07-07',

                'TELE2 5G',
                'TELE2 5G 2023-03-09',

                'Bite 5G',
            ]
        },
        {
            title: 'Datu slāņi',
            collapse: true,
            layers: [
                'dodies.lv Takas',
                'dodies.lv Torņi',
                'dodies.lv Pikniki',
                '1936 Robeža LV-PL 1:4000',
                '1928 Robežpunkti LV-EE 1:25k',
                'Ziemeļvidzemes noklusētie stāsti',
                'Kadastrs OpenData',
                'TKS-93 karšu nomenklatūras lapas',
            ]
        },
        {
            title: 'LVM / LGIA',
            collapse: true,
            layers: [
                'LVM DVM',
                'LVM Ortofoto',
                'LVM Ortofoto 1. Cikls (1994-1999)',
                'LVM Ortofoto 2. Cikls (2003-2005)',
                'LVM Ortofoto 3. Cikls (2007-2008)',
                'LVM Ortofoto 4. Cikls (2010-2011)',
                'LVM Ortofoto 5. Cikls (2013-2015)',
                'LVM Ortofoto 6. Cikls (2016-2018)',
                '1m augstumlīknes',
            ]
        },
        {
            title: 'Pēdējie pievienotie',
            collapse: false,
            layers: [
                'Test Layer 1',

                '1884 Special-Karte von Mittel-Europa 1:200k',
                '1917 Zusammendruck 1:100k',
                '1918 Ubersichtskarte der 8. armee 1:100k',
                '1940 Grosßblatt 1:100k',
            ],
        },
        {
            title: 'Orient',
            collapse: true,
            layers: [
                'Magnēts 2019',
                'Kāpa 2019',
            ]
        },
        {
            title: 'Latvijas izdevumi',
            collapse: true,
            layers: [
                '1918-1919 Brīvības cīņas',
                '1919-1920 Brīvības cīņas',
                '1920-1940 Latvijas Armijas 1:75k',
                '1920-1940 Latvijas Armijas 1:50k',
                '1920-1940 Latvijas Armijas 1:25k',
                '1921 Latvijas karte',
                '1930 Mērniecības planšetes 1:5k',
                '1928 Latvijas satiksmes ceļu Karte 1:400k',
                '1931 Latvijas ceļu un pagastu robežu karte 1:300k',
                '1933 Jaunlatgales apriņķa ceļu karte 1:50k',
                '1934 Latvijas Ceļu Karte 1:300k',
                '1935 Latvijas autoceļi 1:500k',
                '1999 VZD 1:50k',
                '2020 Latvijas Republikas pārskata karte 1:250k',
                '2013 Latvijas Republikas pārskata karte 1:250k',
            ]
        },
        {
            title: 'Vecās kartes',
            collapse: true,
            layers: [
                '17.gs. Zviedru muižas',
                '17.gs. Rīgas patrimoniālā apgabala plāns',
                '18-19. gs PGM',
                '19.gs. Popes muiža',
                '1908 Viena versta 1:42k',
                '1914-1919 West. Osteuropa 1:25k',
                '1914-1919 West. Osteuropa 1:25k PMTILES',
                '1910 Two-verst | West Borderland Territory [Orig] 1:86k',
                '1930 Two-verst | West Borderland Territory [RKKA] 1:86k',
                '1940 RKKA 1:50k',
                '1905 RKKA 1:100k',
                '1940 RKKA 1:200k',
                '1915 Военно-топографическая карта европейской России 1:126k',
                '1916 Feldmäßig hergestellte Fliegerkarte 1:200k',
                '1941 Strassenkarte der Baltischen Länder 1:300k',
                '1942 Deutsche Heereskarte 1:100k',
                '1943 Deutsche Heereskarte 1:300k',
                '1904 Karte des wistlichen Ruslands 1:100k',
                '1924 WIG 1:100k',
                '1917 Vācu armija 1:10k',
                '1917 Vācu armija 1:25k',
                '1940 Estland-Lettland 1:100k',
                '1915 Operationskarte 1:800k',
                '1917 Karte von Kurland 1:50k',
                /*
            ],
        },
        {
            title: 'Liela mēroga vecās kartes',
            collapse: true,
            layers: [
                */
                '1752-1835 Atlas von Liefland',
                '1820 Geograficheskii atlas Rossiiskoi imperii',
                '1867-1918 Военно-топографическая карта европейской России 1:126k',
                '1914-1919 Niemieckie kopie rosyjskiej "trójwiorstówki" 1:126k',
                '1915 Ubersichtskarte von Mitteleuropa 1:300k',
                // '1916 Feldmäßig hergestellte Fliegerkarte 1:200k',
                '1920-1940 Latvijas Armijas 1:200k',
                // '1934 Latvijas Ceļu Karte 1:300k',
                '1940 Latvijas Ceļu Karte 1:200k',
                '1940 RKKA 1:500k',
                // '1940 Estland-Lettland 1:100k',
                // '1941 Strassenkarte der Baltischen Länder 1:300k',
                // '1942 Deutsche Heereskarte 1:100k',
                // '1943 Deutsche Heereskarte 1:300k',
                // '1933 P. Mantnieka 1:250k',
                // '1950 P. Mantnieka 1:450k',
                '1933 P. Mantnieka 1:250k',
                '1950 P. Mantnieka 1:450k',
                '1839 Specialkarte von Livland 1:186k',
                '1905 Specialnaja karta evropejskoj Rossii 1:420k',
                '1954 US Army 250k',
            ],
        },
        {
            title: 'Pilsētu plāni',
            collapse: true,
            layers: [
                '1867 Gouvernements-Stadt Riga. Wieckmann',
                '1883 Karte der stadt Riga 1:4200',
                '1885 Stadplan von Riga 1:12600',
                '1937 Rīga ',
                '1990s Rīga 1:10k',
                '1926 Cēsis ',
                '1798 Valmiera',
                '1936 Rīgas pilsētas plāns 1:24k',
            ]
        },
        {
            title: 'Геренальный штаб',
            collapse: true,
            layers: [
                '1980 Геренальный штаб (C) 1:10k',
                '1980 Геренальный штаб (O) 1:10k',
                '1980 Геренальный штаб (Pilsētas) 1:10k',
                '1947 Геренальный штаб (O Grid) 1:25k',
                '1963 Геренальный штаб (C Grid) 1:25k',
                '1986 Геренальный штаб (O Grid) 1:25k',
                '1980s Геренальный штаб 1:50k',
                '1980s Геренальный штаб 1:100k',
                '1980s Геренальный штаб 1:200k',
                '1980s Геренальный штаб 1:500k',
                '1980s Геренальный штаб 1:1M',
            ],
        },
        {
            title: 'Aerofoto',
            collapse: true,
            layers: [
                '1967-1972 CORONA Satelite ',
                '1940s NARA Aerofoto ',
                'DJI Ortofoto',
            ],
        },
        {
            title: 'Citas',
            collapse: true,
            layers: [
                '1990 VVM Shēmas',
                '1938 Telefonu vadu tīkla shēma',
            ]
        },
    ];

    // last will be on top
    const titlesByOrder = [

        // common base layers
        'OpenStreetMap',
        'ESRI Sat',

        '#custom-bottom',

        'Test Layer 1',

        'LVM DVM',
        'LVM Ortofoto',
        'LVM Ortofoto 1. Cikls (1994-1999)',
        'LVM Ortofoto 2. Cikls (2003-2005)',
        'LVM Ortofoto 3. Cikls (2007-2008)',
        'LVM Ortofoto 4. Cikls (2010-2011)',
        'LVM Ortofoto 5. Cikls (2013-2015)',
        'LVM Ortofoto 6. Cikls (2016-2018)',

        '1918-1919 Brīvības cīņas',
        '1919-1920 Brīvības cīņas',

        '1967-1972 CORONA Satelite ',
        '1940s NARA Aerofoto ',
        '17.gs. Zviedru muižas',
        '17.gs. Rīgas patrimoniālā apgabala plāns',
        '18-19. gs PGM',
        '19.gs. Popes muiža',
        '1752-1835 Atlas von Liefland',

        '1917 Vācu armija 1:10k',
        '1917 Vācu armija 1:25k',
        '1915 Operationskarte 1:800k',
        '1921 Latvijas karte',
        '1928 Latvijas satiksmes ceļu Karte 1:400k',
        '1931 Latvijas ceļu un pagastu robežu karte 1:300k',
        '1933 Jaunlatgales apriņķa ceļu karte 1:50k',
        '1935 Latvijas autoceļi 1:500k',
        '1940 Estland-Lettland 1:100k',

        '2020 Latvijas Republikas pārskata karte 1:250k',
        '2013 Latvijas Republikas pārskata karte 1:250k',

        '1820 Geograficheskii atlas Rossiiskoi imperii',
        '1905 Specialnaja karta evropejskoj Rossii 1:420k',

        '1940 RKKA 1:500k',
        '1950 P. Mantnieka 1:450k',
        '1941 Strassenkarte der Baltischen Länder 1:300k',
        '1943 Deutsche Heereskarte 1:300k',

        '1915 Ubersichtskarte von Mitteleuropa 1:300k',
        '1934 Latvijas Ceļu Karte 1:300k',
        '1954 US Army 250k',
        '1933 P. Mantnieka 1:250k',
        '1940 Latvijas Ceļu Karte 1:200k',
        '1916 Feldmäßig hergestellte Fliegerkarte 1:200k',
        '1839 Specialkarte von Livland 1:186k',
        '1867-1918 Военно-топографическая карта европейской России 1:126k',
        '1914-1919 Niemieckie kopie rosyjskiej "trójwiorstówki" 1:126k',
        '1915 Военно-топографическая карта европейской России 1:126k',
        '1942 Deutsche Heereskarte 1:100k',

        // genshtab
        '1980s Геренальный штаб 1:1M',
        '1980s Геренальный штаб 1:500k',
        '1980s Геренальный штаб 1:200k',
        '1980s Геренальный штаб 1:100k',
        '1980s Геренальный штаб 1:50k',
        '1947 Геренальный штаб (O Grid) 1:25k',
        '1963 Геренальный штаб (C Grid) 1:25k',
        '1986 Геренальный штаб (O Grid) 1:25k',
        '1980 Геренальный штаб (C) 1:10k',
        '1980 Геренальный штаб (O) 1:10k',
        '1980 Геренальный штаб (Pilsētas) 1:10k',

        '1940 RKKA 1:200k',
        '1905 RKKA 1:100k',
        '1904 Karte des wistlichen Ruslands 1:100k',
        '1924 WIG 1:100k',
        '1930 Two-verst | West Borderland Territory [RKKA] 1:86k',
        '1910 Two-verst | West Borderland Territory [Orig] 1:86k',

        '1940 RKKA 1:50k',
        '1917 Karte von Kurland 1:50k',
        '1999 VZD 1:50k',

        '1908 Viena versta 1:42k',

        '1920-1940 Latvijas Armijas 1:200k',
        '1920-1940 Latvijas Armijas 1:75k',
        '1920-1940 Latvijas Armijas 1:50k',
        '1920-1940 Latvijas Armijas 1:25k',
        '1914-1919 West. Osteuropa 1:25k',
        '1914-1919 West. Osteuropa 1:25k PMTILES',

        '1936 Rīgas pilsētas plāns 1:24k',
        '1990s Rīga 1:10k',
        '1930 Mērniecības planšetes 1:5k',

        '1883 Karte der stadt Riga 1:4200',
        '1867 Gouvernements-Stadt Riga. Wieckmann',
        '1885 Stadplan von Riga 1:12600',
        '1937 Rīga ',
        '1926 Cēsis ',
        '1798 Valmiera',

        '1884 Special-Karte von Mittel-Europa 1:200k',
        '1917 Zusammendruck 1:100k',
        '1918 Ubersichtskarte der 8. armee 1:100k',
        '1940 Grosßblatt 1:100k',

        // overlays
        '1936 Robeža LV-PL 1:4000',
        '1928 Robežpunkti LV-EE 1:25k',
        'Ziemeļvidzemes noklusētie stāsti',
        '1990 VVM Shēmas',
        '1938 Telefonu vadu tīkla shēma',
        'DJI Ortofoto',

        'Magnēts 2019',
        'Kāpa 2019',

        'LMT 5G',
        'LMT 5G 2023-07-07',
        'TELE2 5G',
        'TELE2 5G 2023-03-09',
        'Bite 5G',

        '1m augstumlīknes',
        'Kadastrs OpenData',

        'TKS-93 karšu nomenklatūras lapas',

        'dodies.lv Takas',
        'dodies.lv Torņi',
        'dodies.lv Pikniki',

        '#custom-top',
    ];

function getLayers(tracklist) {
    // set metadata
    for (let layer of layersDefs) {
        layer.layer.meta = {title: layer.title};
        layer.layer.tracklist = tracklist;
        layer.maxZoom = 21;
    }

    // assign order to layers
    const orderByTitle = {};
    for (let i = 0; i < titlesByOrder.length; i++) {
        let title = titlesByOrder[i];
        orderByTitle[title] = i + 1;
    }

    for (let layer of layersDefs) {
        const title = layer.title;
        layer.order = orderByTitle[title];
        if (!layer.order) {
            throw new Error(`Layer title not found in titlesByOrder list: ${title}`);
        }
    }

    // divide layers by groups
    const grouppedLayers = [];
    const layersByTitle = {};
    for (let layer of layersDefs) {
        layersByTitle[layer.title] = layer;
    }
    for (let groupDef of groupsDefs) {
        let collapse = groupDef.collapse === true;
        let group = {group: groupDef.title, collapse: collapse, layers: []};
        grouppedLayers.push(group);
        for (let title of groupDef.layers) {
            let layer = layersByTitle[title];
            group.layers.push(layer);
        }
    }

    return {
        layers: grouppedLayers,
        customLayersOrder: {
            top: orderByTitle['#custom-top'],
            bottom: orderByTitle['#custom-bottom'],

        }
    };
}

export {getLayers, layersDefs, groupsDefs, titlesByOrder};
