const secrets = {
    bingKey: '0000000000000000000000000000000000000000000000000000000000000000',
    thunderforestKey: '00000000000000000000000000000000',
    sentryDSN: 'https://00000000000000000000000000000000@sentry.io/111111',
    mapillary: '0000000000000000000000000000000000000000000000000000',
    flickr: '00000000000000000000000000000000',
    google: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
};

export default secrets;
